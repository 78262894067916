*{
    margin:0;
    padding:0;
    box-sizing: border-box;
}

body{
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    background: #f1f5f8;
    color: #222;
}

img {
    width: 150px;
    height: 250px;
}

.booklist{
    width: 90vw;
    max-width: 1170px;
    margin: 5rem auto;
    display: grid;
    gap: 2rem;
}
@media screen and (min-width: 768px){
    .booklist{
        grid-template-columns: repeat(3, 1fr);
    }
}

.book{
  background:#fff;
  border-radius: 1rem;
  padding:1rem 2rem; 
}

.book h1{
    margin-top: 0.5rem
}

.book h4 {
    color: #617d98;
    font-size: 0.75rem;
    margin-top: 0.25rem;
}

.book p{
    margin-top: 0.5rem;
}